.App {}

/* ========================================================================== */
/* React Error Popup */
/* ========================================================================== */
body>iframe {
    display: none;
}

/* ========================================================================== */
/* Html */
/* ========================================================================== */
html {
    background-image: url(../assets/imgs/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    font-family: LouisGeorgeCafeBold;
}

body {
    margin: 0;
    /* Remove default body margin to ensure background covers the entire viewport */
    height: 100%;
    font-family: LouisGeorgeCafeBold;
}

p {
    font-family: LouisGeorgeCafeBold;
    color: white;
}

.header {
    font-family: LouisGeorgeCafeBold;
    color: rgba(255, 255, 255, 1.0);
    font-size: 54px;
    white-space: nowrap;
    /* Prevent text wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Add ellipsis (...) to indicate overflow */
}

.header-faded {
    font-family: LouisGeorgeCafeBold;
    color: rgba(255, 255, 255, 0.5);
    font-size: 48px;
}

.subheader {
    font-family: LouisGeorgeCafeBold;
    color: rgba(255, 255, 255, 0.7);
    font-size: 38px;
}

.welcome-header {
    font-family: LouisGeorgeCafeBold;
    color: rgba(255, 255, 255, 0.5);
    font-size: 32px;
}

.nickname-header {
    font-family: LouisGeorgeCafeBold;
    color: rgba(255, 255, 255, 1.0);
    font-size: 32px;
}

.center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000000;
    padding: 20px;
    text-align: center;
}

.center-boarder {
    color: rgb(0, 0, 0, 0.0);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
}


.overlay_button_container {
    position: fixed;
    top: 2%;
    left: 2%;
    height: 36px;
    width: 36px;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease;
}

.welcome-header,
.header,
.img,
.button.fixedSize {
    margin-bottom: 10px;
    /* Adjust as needed */
}

/* ========================================================================== */
/* Btns */
/* ========================================================================== */
.button {
    font-family: LouisGeorgeCafeBold;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    border: 0px solid #00000000;
    padding: 16px 64px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    text-shadow: none;
    position: relative;
    /* Make it relative for absolute positioning of the icon */
}

.button.fixedSize {
    width: 300px;
    height: 42px;
    /* Adjust the height as needed */
    padding: 16px 16px;
    /* Adjust padding as needed */
    display: flex;
    /* Use flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center text horizontally */
}

.button.fixedSize img {
    position: absolute;
    left: 16px;
    /* Fixed distance from the left */
}

.button.iconRight.fixedSize img {
    left: auto;
    right: 16px;
    /* Fixed distance from the right */
}

.button:hover,
.button:focus {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
}

.button:active {
    background-color: rgba(0, 0, 0, 1.0);
    color: #fff;
}

.button-link {
    color: #fff;
    /* Match button text color */
    text-decoration: underline;
    /* Underline the link */
    margin-left: 4px;
    /* Adjust spacing between text and link */
}

.button-link:hover {
    color: #ccc;
    /* Change color on hover for better visibility */
    text-decoration: underline;
    /* Keep underline on hover */
}

.facebook-btn {
    background-color: #4267B2;
}

.facebook-btn:disabled {
    background-color: rgba(66, 103, 178, 0.3);
}

.google-btn {
    background-color: #ffffff;
    color: #909090;
}

.google-btn:disabled {
    background-color: rgba(255, 255, 255, 0.3);
    color: #909090;
}

/* ========================================================================== */
/* Text Button */
/* ========================================================================== */
.text-btn {
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 16px 64px;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: relative;
}

.text-btn:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.text-btn:hover:before,
.text-btn:focus:before {
    visibility: visible;
    width: 100%;
}

.text-btn.fixedSize {
    width: 300px;
    height: 42px;
    /* Adjust the height as needed */
    padding: 16px 16px;
    /* Adjust padding as needed */
    display: flex;
    /* Use flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: flex-end;
    /* Align text to the right */
}

.text-btn:hover,
.text-btn:focus {
    background-color: transparent;
    color: #ffffff;
}

/* ========================================================================
   Component: Toggle Button
 ========================================================================== */
.toggle-button {
    font-family: LouisGeorgeCafeBold;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    border: 0px solid #00000000;
    padding: 16px 64px;
    text-align: left;
    /* Align text to the left */
    text-decoration: none;
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: flex-start;
    /* Align items to the start (left) */
    font-size: 14px;
    margin: 2px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    text-shadow: none;
    position: relative;
    /* Make it relative for absolute positioning of the checkbox */
    flex-wrap: wrap;
    /* Allow items to wrap if necessary */
}

.toggle-button.fixedSize {
    width: 300px;
    /* Adjust width as needed */
    height: 42px;
    /* Adjust height as needed */
    padding: 4px 8px;
    /* Adjust padding as needed */
}

.toggle-button .checkbox {
    width: 16px;
    /* Adjust size as needed */
    height: 16px;
    /* Adjust size as needed */
    cursor: pointer;
    margin-right: 10px;
    /* Space between checkbox and text */
}

.toggle-button .text {
    /* The text container should take up remaining space and handle text overflow */
    flex: 1;
    /* Allow text to take up available space */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Show ellipsis if text overflows */
}


.toggle-button.on {
    background-color: rgba(0, 128, 0, 0.65);
    /* Green background for ON state */
}

.toggle-button.on:hover,
.toggle-button.on:focus {
    background-color: rgba(0, 128, 0, 0.85);
    color: #fff;
}

.toggle-button.on:active {
    background-color: rgba(0, 128, 0, 1.0);
    color: #fff;
}

.toggle-button.off {
    background-color: rgba(0, 0, 0, 0.65);
}

.toggle-button.off:hover,
.toggle-button.off:focus {
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
}

.toggle-button.off:active {
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
}

/* ========================================================================
   Component: Text fields
 ========================================================================== */
 input, textarea, select {
    font-size: 16px;
}

.input-container {
    position: relative;
    /* Make the container relative for absolute positioning of the icon */
    display: flex;
    /* Use flexbox for alignment */
    align-items: center;
    /* Center items vertically */
    width: 300px;
    /* Match the width of the input field */
    margin: 4px 2px;
    /* Match button margin */
}

.input-field {
    font-family: LouisGeorgeCafeBold;
    background-color: rgba(255, 255, 255, 0.85);
    /* Match the button background color */
    color: #000000;
    /* Match the button text color */
    border: 0px solid #ffffff00;
    /* Border color similar to buttons */
    padding: 12px 20px;
    /* Adjust padding for better fit */
    padding-left: 40px;
    /* Add padding to accommodate the icon */
    font-size: 16px;
    width: 100%;
    /* Make input field take full width of the container */
    box-sizing: border-box;
    /* Include padding and border in the element’s total width and height */
}

.input-field:focus {
    outline: none;
    background-color: #fff;
    color: #666;
    border-color: rgba(0, 0, 0, 1.0);
}

.input-icon {
    position: absolute;
    left: 12px;
    /* Fixed distance from the left */
    top: 50%;
    transform: translateY(-50%);
    /* Center vertically */
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
}

.input-container.iconRight .input-field {
    padding-left: 20px;
    /* Reset left padding */
    padding-right: 40px;
    /* Add padding to accommodate the icon on the right */
}

.input-container.iconRight .input-icon {
    left: auto;
    /* Reset left positioning */
    right: 12px;
    /* Fixed distance from the right */
}

/* ========================================================================== */
/* Other */
/* ========================================================================== */
.square-border {
    display: inline-block;
    /* Ensures the div wraps around its content */
    padding: 6px;
    /* Adds 10px padding around the image */
    background-color: rgba(0, 0, 0, 0.6);
    /* Optional: Set a background color for the element */
    box-sizing: border-box;
    /* Ensure padding and border are included in the element’s total width and height */
    text-align: center;
    /* Center-align the text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Add a drop shadow */
}

.square-border img {
    display: block;
    /* Ensures the image takes up its own line */
    margin: 0 auto 2px;
    /* Center the image horizontally */
}

.square-border p {
    margin: 0;
    /* Remove default margin from paragraph */
}

/* ========================================================================== */
/* Modal */
/* ========================================================================== */
.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Can prob delete */
.toggle-modal-btn {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 9999;
}

/* ========================================================================== */
/* Unity */
/* ========================================================================== */
.unity-content {
    visibility: hidden;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.unity-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



/* ========================================================================== */
/* util */
/* ========================================================================== */
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

/* ========================================================================== */
/* animation */
/* ========================================================================== */
.fadeIn {
    -moz-animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    -ms-animation-name: fadeIn;
    animation-name: fadeIn;
    -moz-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.fadeOut {
    -moz-animation-name: fadeOut;
    -webkit-animation-name: fadeOut;
    -ms-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.bottle-loader {
    width: 40px;
    height: 98px;
    display: inline-block;
    position: relative;
    border: 2px solid #FFF;
    box-sizing: border-box;
    color: #eea0ff;
    border-radius: 20px 20px 4px 4px;
    background: #fff;
    animation: fill 5s linear infinite alternate;
  }
  
  .bottle-loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -95%);
    border: 2px solid #FFF;
    border-bottom: none;
    background: #fff;
    width: 15px;
    height: 35px;
    animation: fillNeck 5s linear infinite alternate;
  }
  
  .bottle-loader .bubble {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent white bubble */
    border-radius: 50%; /* Make it a circle */
    width: 8px; /* Bubble size */
    height: 8px; /* Bubble size */
    animation: bubbleMove 2s linear infinite;
  }
  
  .bottle-loader .bubble:nth-child(1) {
    left: 10%;
    animation-delay: 0s;
  }
  
  .bottle-loader .bubble:nth-child(2) {
    left: 50%;
    animation-delay: 0.5s;
  }
  
  .bottle-loader .bubble:nth-child(3) {
    left: 80%;
    animation-delay: 1s;
  }
  
  @keyframes fill {
    0% { 
      box-shadow: 0 0 inset;
    }
    50%, 100% { 
      box-shadow: 0 -98px inset;
    }
  }
  
  @keyframes fillNeck {
    0%, 50% {
      box-shadow: 0 0 inset;
    }
    100% {
      box-shadow: 0 -20px inset;
    }
  }
  
  @keyframes bubbleMove {
    0% {
      top: 80%; /* Start at the bottom */
      opacity: 1;
    }
    100% {
      top: 0%; /* Move to the top */
      opacity: 0; /* Fade out */
    }
  }
  



/* ========================================================================== */
/* fonts */
/* ========================================================================== */
@font-face {
    font-family: LouisGeorgeCafeBold;
    src: url(../assets/fonts/LouisGeorgeCafeBold.ttf);
}

* {
    font-family: LouisGeorgeCafeBold;
}