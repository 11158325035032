/* ModalInput.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.875);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgba(0, 0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content h1 {
  margin: 0;
  margin-bottom: 10px;
}

.modal-content p {
  margin: 0;
  margin-bottom: 20px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}
